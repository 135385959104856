<template>
  <b-modal
    v-model="conditionalModal"
    centered
    size="lg"
    :title="'Log Sessions ' + user.name"
    ok-title="Ok"
    ok-only
    hide-footer
    no-close-on-backdrop
    @hide="onHide"
  >
    <b-table
      :items="items"
      :fields="fields"
      hover
      bordered
      responsive
      primary-key="id"
      class="position-relative"
      show-empty
      empty-text="No matching records found"
    >
      <!-- <template #cell(attempt_request_risk_data.email.risk)="data">
                <b-badge
                    :variant="data.value ? 'danger' : 'info'"
                    class="badge-glow cursor-pointer"
                >
                    <span class="align-text-top text-capitalize">
                    {{ data.value ? 'High' : 'Low' }}
                    </span>
                </b-badge>
            </template> -->
    </b-table>
    <PaginationTable
      :dataMeta="dataMeta"
      :totalUsers="totalUsers"
      :perPage="perPage"
      :currentPage="currentPage"
      @page-changed="pageChanged"
    />
  </b-modal>
</template>

<script>
import { BModal, BBadge, BTable, BPagination } from "bootstrap-vue";

import axiosU from "@/services/admin/user";

import PaginationTable from "@/components/PaginationTable";

export default {
  components: {
    BModal,
    BBadge,
    BTable,
    BPagination,
    PaginationTable,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getDetails();
  },
  data() {
    return {
      conditionalModal: true,
      fields: [
        { key: "id", thClass: "px-3" },
        { key: "loggging_ip", label: "Ip" },
        { key: "loggging_at", label: "Login At" },
        { key: "logout_at", label: "Logout At" },
      ],
      items: [],
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      searchQuery: "",
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      loading: false,
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalUserLogSessions", false);
    },
    getDetails() {
      axiosU
        .userLogSession(this.perPage, this.user.id)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      axiosU
        .userLogSessionPaginate(this.perPage, this.user.id, page)
        .then(({ data, total, current_page, ...res }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
  },
};
</script>

<style scoped>
.city {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>
