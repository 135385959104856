<template>
  <div>
    <UsersListFilters
      v-if="
        $can(
          'filter',
          'module_system_settings.settings.admin_user.type_of_user'
        )
      "
      :loading="loading"
      :filterAddUser="filterAddUser"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @createUser="createUser"
      @createUserData="createUserData"
    />

    <goalScore :goal-modal.sync="seeGoalModal" :userId="userId" />

    <ModalUserLogSessions
      v-if="conditionalModal"
      :user="itemSession"
      @closeModalUserLogSessions="closeModalUserLogSessions"
    />
    <!-- @refetch-data="refetchData" -->
    <!-- :role-options="roleOptions"
      :plan-options="planOptions" -->

    <!-- {{ $t("Filters") }} -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <b-card no-body class="mb-0">
      <TitleTable titleTable="Users" iconTable="UserIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>

          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                v-if="
                  $can(
                    'store',
                    'module_system_settings.settings.admin_user.type_of_user'
                  )
                "
              >
                <span class="text-nowrap">{{ $t("Add") }} User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        bordered
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: View Details -->
        <template #cell(view)="data">
          <b-form-checkbox
            v-model="data.detailsShowing"
            @change="data.toggleDetails"
          />
        </template>

        <template #row-details="data">
          {{ data.item[0] }}
          <UserViewUserInfoCard :userData="data.item">
            <slot>
              <b-button
                size="sm"
                class="my-1"
                variant="outline-primary"
                @click="data.toggleDetails"
              >
                hide details
              </b-button>
            </slot>
          </UserViewUserInfoCard>
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{
                  name: 'web-settings-users',
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{ name: 'web-settings-users', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.name }}</small>
          </b-media>
        </template>

        <!-- Column: Facility -->
        <template #cell(facility)="data">
          <b-badge
            variant="primary"
            v-for="(facility, i) in data.item.facilities"
            :key="i"
            >{{ facility.name }}</b-badge
          >
        </template>

        <!-- Column: Role -->
        <template #cell(roles)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.roles[0].name)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.roles[0].name)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.roles[0].name
            }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="resolveUserStatusVariant(data.item.status)"
              class="badge-glow cursor-pointer"
              @click="changeStatus(data.item)"
            >
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t("Active") : $t("Inactive")
              }}</span>
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                $can(
                  'log_sessions',
                  'module_system_settings.settings.admin_user.type_of_user'
                )
              "
              @click="viewLogs(data.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Log Sessions</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can(
                  'index',
                  'module_system_settings.settings.admin_user.goal_score'
                )
              "
              @click="viewScore(data.item)"
            >
              <feather-icon icon="StarIcon" />
              <span class="align-middle ml-50">See Goal Score</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can(
                  'show',
                  'module_system_settings.settings.admin_user.type_of_user'
                )
              "
              :to="{
                name: 'web-settings-users-view',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can(
                  'update',
                  'module_system_settings.settings.admin_user.type_of_user'
                )
              "
              :to="{
                name: 'web-settings-users-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can(
                  'destroy',
                  'module_system_settings.settings.admin_user.type_of_user'
                )
              "
              @click="deleteUser(data.item)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack.vue";
import ToastNotification from "@/components/ToastNotification";
import { avatarText } from "@core/utils/filter";

import UserViewUserInfoCard from "../users-view/UserViewUserInfoCard.vue";
import UsersListFilters from "./UsersListFilters.vue";
import UserListAddNew from "./UserListAddNew.vue";
import ModalUserLogSessions from "../users-log-session/ModalLogSessions.vue";
import TitleTable from "@/components/TitleTable.vue";

import PaginationTable from "@/components/PaginationTable";

import axiosU from "@/services/admin/user";
import goalScore from "@/components/GoalScore";

export default {
  components: {
    BCard,
    goalScore,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    UserViewUserInfoCard,
    UsersListFilters,
    UserListAddNew,
    ModalUserLogSessions,
    TitleTable,
    avatarText,
    ToastNotification,
    GoBack,
    PaginationTable,
  },
  data() {
    return {
      tableColumns: [
        { key: "view", sortable: false },
        { key: "user", sortable: true },
        { key: "email", sortable: true },
        { key: "facility", sortable: true },
        { key: "roles", sortable: true },
        { key: "status", sortable: true },
        { key: "actions" },
      ],
      items: [],
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
      searchQuery: "",
      sortBy: "id",
      isSortDirDesc: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      loading: false,
      isAddNewUserSidebarActive: false,
      filtro: false,
      userFilter: null,
      conditionalModal: false,
      seeGoalModal: false,
      userId: 0,
      itemSession: null,
      filterAddUser: null,
    };
  },
  mounted() {
    this.getUser();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getUser();
      } else {
        this.searchFilter(this.userFilter);
      }
    },
  },
  methods: {
    viewScore(item) {
      this.userId = item.id
      this.seeGoalModal = true;
    },
    getUser() {
      axiosU
        .userList(this.perPage)
        .then(({ data, total, current_page, ...res }) => {
          const datos = data.map((item) => {
            item.role = item.roles.length > 0 ? item.roles[0].name : "No Role";
            return item;
          });
          this.items = datos;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    avatarText(name) {
      return avatarText(name);
    },
    createUser(value) {
      if (value) {
        this.isAddNewUserSidebarActive = false;
        this.$refs.toast.success("User has been created successfully");
      } else {
        this.$refs.toast.error("User has not been created");
      }
      this.pageChanged(this.currentPage.page);
    },
    createUserData(value) {
      console.log(value);
      if (value) {
        this.filterAddUser = value.email;
      }
    },
    resolveUserRoleVariant(role) {
      if (role === "FRONT-DESK") return "warning";
      if (role === "ADMIN") return "success";
      if (role === "NURSE") return "info";
      if (role === "OMA") return "info";
      if (role === "SUPER-ADMIN") return "danger";
      return "primary";
    },
    resolveUserRoleIcon(role) {
      if (role === "FRONT-DESK") return "PhoneIcon";
      if (role === "OMA") return "HeartIcon";
      if (role === "NURSE") return "HeartIcon";
      if (role === "ADMIN") return "Edit2Icon";
      if (role === "SUPER-ADMIN") return "ServerIcon";
      return "UserIcon";
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
    changeStatus(item) {
      axiosU
        .userUpdateState(item.id, { status: !item.status })
        .then((res) => {
          this.$refs.toast.success("User status has been changed successfully");
        })
        .catch((err) => {
          this.$refs.toast.error("User status has not been changed");
        });

      this.pageChanged(this.currentPage.page);
    },
    deleteUser(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosU.userDelete(item.id).then((response) => {
            this.$refs.toast.success("User has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosU
          .userListPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosU
          .userFilterPagination(this.perPage, page, this.userFilter)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.userFilter = value;
        axiosU
          .userFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getUser();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
    viewLogs(item) {
      this.conditionalModal = true;
      this.itemSession = item;
    },
    closeModalUserLogSessions(value) {
      this.conditionalModal = value;
    },
  },
};
</script>

<style></style>
