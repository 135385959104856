var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$can(
        'filter',
        'module_system_settings.settings.admin_user.type_of_user'
      )
    )?_c('UsersListFilters',{attrs:{"loading":_vm.loading,"filterAddUser":_vm.filterAddUser},on:{"searchFilter":_vm.searchFilter,"filterPage":_vm.filterPage}}):_vm._e(),_c('user-list-add-new',{attrs:{"is-add-new-user-sidebar-active":_vm.isAddNewUserSidebarActive},on:{"update:isAddNewUserSidebarActive":function($event){_vm.isAddNewUserSidebarActive=$event},"update:is-add-new-user-sidebar-active":function($event){_vm.isAddNewUserSidebarActive=$event},"createUser":_vm.createUser,"createUserData":_vm.createUserData}}),_c('goalScore',{attrs:{"goal-modal":_vm.seeGoalModal,"userId":_vm.userId},on:{"update:goalModal":function($event){_vm.seeGoalModal=$event},"update:goal-modal":function($event){_vm.seeGoalModal=$event}}}),(_vm.conditionalModal)?_c('ModalUserLogSessions',{attrs:{"user":_vm.itemSession},on:{"closeModalUserLogSessions":_vm.closeModalUserLogSessions}}):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('TitleTable',{attrs:{"titleTable":"Users","iconTable":"UserIcon"}}),_c('div',{staticClass:"m-1"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Show")))]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v(_vm._s(_vm.$t("Entries")))])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('GoBack',{staticClass:"mr-2"}),(
                _vm.$can(
                  'store',
                  'module_system_settings.settings.admin_user.type_of_user'
                )
              )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isAddNewUserSidebarActive = true}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("Add"))+" User")])]):_vm._e()],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.items,"responsive":"","bordered":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(view)",fn:function(data){return [_c('b-form-checkbox',{on:{"change":data.toggleDetails},model:{value:(data.detailsShowing),callback:function ($$v) {_vm.$set(data, "detailsShowing", $$v)},expression:"data.detailsShowing"}})]}},{key:"row-details",fn:function(data){return [_vm._v(" "+_vm._s(data.item[0])+" "),_c('UserViewUserInfoCard',{attrs:{"userData":data.item}},[_vm._t("default",function(){return [_c('b-button',{staticClass:"my-1",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":data.toggleDetails}},[_vm._v(" hide details ")])]})],2)]}},{key:"cell(user)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.name),"variant":("light-" + (_vm.resolveUserRoleVariant(data.item.role))),"to":{
                name: 'web-settings-users',
                params: { id: data.item.id },
              }}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{ name: 'web-settings-users', params: { id: data.item.id } }}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(data.item.name))])],1)]}},{key:"cell(facility)",fn:function(data){return _vm._l((data.item.facilities),function(facility,i){return _c('b-badge',{key:i,attrs:{"variant":"primary"}},[_vm._v(_vm._s(facility.name))])})}},{key:"cell(roles)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",class:("text-" + (_vm.resolveUserRoleVariant(data.item.roles[0].name))),attrs:{"icon":_vm.resolveUserRoleIcon(data.item.roles[0].name),"size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.roles[0].name))])],1)]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"badge-glow cursor-pointer",attrs:{"variant":_vm.resolveUserStatusVariant(data.item.status)},on:{"click":function($event){return _vm.changeStatus(data.item)}}},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.status ? _vm.$t("Active") : _vm.$t("Inactive")))])])],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.$can(
                'log_sessions',
                'module_system_settings.settings.admin_user.type_of_user'
              )
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewLogs(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Log Sessions")])],1):_vm._e(),(
              _vm.$can(
                'index',
                'module_system_settings.settings.admin_user.goal_score'
              )
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewScore(data.item)}}},[_c('feather-icon',{attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("See Goal Score")])],1):_vm._e(),(
              _vm.$can(
                'show',
                'module_system_settings.settings.admin_user.type_of_user'
              )
            )?_c('b-dropdown-item',{attrs:{"to":{
              name: 'web-settings-users-view',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Details")])],1):_vm._e(),(
              _vm.$can(
                'update',
                'module_system_settings.settings.admin_user.type_of_user'
              )
            )?_c('b-dropdown-item',{attrs:{"to":{
              name: 'web-settings-users-edit',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1):_vm._e(),(
              _vm.$can(
                'destroy',
                'module_system_settings.settings.admin_user.type_of_user'
              )
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteUser(data.item)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1):_vm._e()],1)]}}],null,true)}),_c('PaginationTable',{attrs:{"dataMeta":_vm.dataMeta,"totalUsers":_vm.totalUsers,"perPage":_vm.perPage,"currentPage":_vm.currentPage},on:{"page-changed":_vm.pageChanged}})],1),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }