<template>
  <div>
    <b-modal
      :visible="goalModal"
      size="xl"
      @change="(val) => $emit('update:goal-modal', val)"
    >
      <b-card>
        <b-row>
          <b-col cols="auto" class="d-flex flex-column">
            <label>Month</label>
            <month-picker-input
              :no-default="true"
              @change="showDate"
              style="z-index: 999"
            />
          </b-col>
          <b-col class="d-flex flex-column">
            <label>Date Range</label>
            <div class="d-flex align-items-center flex-wrap">
              <flat-pickr
                v-model="filters.date_start"
                :config="fConfig"
                class="form-control"
                placeholder="MM-DD-YYYY H:i"
              />
              <flat-pickr
                v-model="filters.date_end"
                :config="uConfig"
                class="form-control ml-xl-1"
                placeholder="MM-DD-YYYY H:i"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button
              variant="primary"
              @click="searchFilter"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-progress
          show-progress
          class="my-2 rounded-lg roundedCustom"
          :max="totalScore"
          height="3rem"
          :variant="getVariantType(totalScoreUser)"
        >
          <b-progress-bar
            :value="totalScoreUser"
            :label="`${totalScoreUser} / ${totalScore}`"
          ></b-progress-bar>
        </b-progress>
        <b-table
          :items="userItem"
          :fields="fields"
          class="position-relative"
          responsive
          show-empty
          empty-text="No matching records found"
          bordered
        >
          <template #cell(score)="data">
            <b-form-group>
              <div class="d-flex">
                <b-form-radio-group
                  value-field="item"
                  text-field="name"
                  :id="`radio-group-${data.index}`"
                  v-model="selected[data.index]"
                  @change="
                    userScore(data.item.pivot.goal_id, selected[data.index])
                  "
                >
                  <b-form-radio
                    v-for="(option, index) in scorePerItem[data.index]"
                    :key="index"
                    :id="`radio-${data.index}-${index}`"
                    :value="option.item"
                    :name="`radio-group-${data.index}`"
                    v-b-tooltip.hover.top="option.hover"
                  >
                    {{ option.name }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </b-form-group>
          </template>
        </b-table>
      </b-card>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import ToastNotification from "@/components/ToastNotification";
import flatPickr from "vue-flatpickr-component";
import { ref, watch } from "@vue/composition-api";
import { MonthPickerInput } from "vue-month-picker";
import axiosS from "@/services/admin/goals/goalScore";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";
import { notificationCheck } from "@/mixins/NotificationSetup";
import SpinnerLoading from "./SpinnerLoading.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    MonthPickerInput,
    BCard,
    SpinnerLoading,
    BTable,
    BButton,
    flatPickr,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    BProgress,
    BProgressBar,
    ToastNotification,
  },
  model: {
    prop: "goalModal",
    event: "update:goal-modal",
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    userId: {
      type: Number,
      default: 0,
      required: true,
    },
    goalModal: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props) {
    watch(
      props,
      () => {
        if (props.goalModal == true) showScoreUser();
      },
      { deep: true }
    );
    const toast = ref(null);
    const loading = ref(false);
    const fConfig = ref(
      fromConfig({ isStatic: true, dateFormat: "m-Y", defaultHour: false })
    );
    const uConfig = ref(
      untilConfig({ isStatic: true, dateFormat: "m-Y", defaultHour: false })
    );
    const scorePerItem = ref();
    const selected = ref();
    const userItem = ref(null);
    const fields = ref([
      { key: "name" },
      { key: "description" },
      { key: "score", label: "Score" },
    ]);
    const totalScoreUser = ref(0);
    const totalScore = ref(0);
    const scoreHover = ref({
      Puntualidad: [
        "Tiene mas de 5 llegadas tarde o errores al marcar",
        "Tiene 4 llegadas tarde o errores al marcar",
        "Tiene 3 llegadas tarde o errores al marcar",
        "Tiene 1-2 llegadas tarde o errores al marcar",
        "No tiene llegadas tarde",
      ],
      ["Cumplimiento de obligaciones"]: [
        "No cumple en 4 ocasiones",
        "No cumple en 3 ocasiones",
        "No cumple en 2 ocasiones",
        "No cumple en 1 ocasión",
        "Cumple satisfactoriamente",
      ],
      ["Desempeño Individual"]: [
        "No cumple en 4 ocasiones",
        "No cumple en 3 ocasiones",
        "No cumple en 2 ocasiones",
        "No cumple en 1 ocasión",
        "Cumple satisfactoriamente",
      ],
      ["Desempeño con el paciente"]: [
        "Tiene 4 reviews",
        "Tiene 3 reviews",
        "Tiene 2 reviews",
        "Tiene 1 review",
        "No tiene reviews de 1 a 3 estrellas",
      ],
    });

    const filters = ref({
      date_start: "",
      date_end: "",
      user_id: props.userId,
    });

    function resetFilter() {
      filters.value.date_end = "";
      filters.value.date_start = "";
    }

    function searchFilter() {
      loading.value = true;
      filters.value.user_id = props.userId;
      axiosS.goalUserFilter(filters.value, 10, 1).then(({ registro }) => {
        constructData(registro.data[0]);
        loading.value = false;
      });
    }
    function showDate({ monthIndex, year }) {
      const firstDay = new Date(year, monthIndex - 1);
      const lasttDay = new Date(year, monthIndex - 1 + 1);

      const firstDayFormat =
        (firstDay.getMonth() + 1).toString().padStart(2, "0") +
        // "-" +
        // firstDay.getDate().toString().padStart(2, "0") +
        "-" +
        firstDay.getFullYear();
      const lastDayFormat =
        (lasttDay.getMonth() + 1).toString().padStart(2, "0") +
        // "-" +
        // lasttDay.getDate().toString().padStart(2, "0") +
        "-" +
        lasttDay.getFullYear();

      filters.value.date_start = firstDayFormat;
      filters.value.date_end = lastDayFormat;
    }

    function showScoreUser() {
      if (props.userId) {
        selected.value = {};
        scorePerItem.value = {};
        axiosS.goalUserInfo(props.userId).then(({ registro }) => {
          constructData(registro);
        });
      }
    }

    function constructData(registro) {
      totalScoreUser.value = 0;
      totalScore.value = 0;
      const { objectifiable } = registro;
      userItem.value = objectifiable.sort((a, b) => a.goal_id - b.goal_id);
      const data = {};
      objectifiable.forEach((item, index) => {
        totalScoreUser.value += +item.pivot.score;
        totalScore.value += +item.pivot.goal;
        data[index] = [];
        const { pivot } = item;
        selected.value[index] = pivot.score;
        for (let i = 0; i <= pivot.goal; i++) {
          data[index].push({
            item: i,
            hover: scoreHover.value[item.name]
              ? scoreHover.value[item.name][i]
              : "",
            name: i,
          });
        }
      });
      scorePerItem.value = data;
    }

    function userScore(idItem, itemScore) {
      totalScoreUser.value = 0;
      Object.values(selected.value).forEach((item) => {
        totalScoreUser.value += +item;
      });
      const data = {
        goal_id: idItem,
        score: itemScore,
      };
      axiosS.goalUserUpdate(props.userId, data).then((res) => {
        const { type, message } = notificationCheck(res);
        toast.value[type](message);
      });
    }

    function onChangeUser() {
      totalScoreUser.value = 0;
      totalScore.value = 0;
    }

    function getVariantType(item) {
      if (item >= 24) return "success";
      return "warning";
    }

    return {
      toast,
      getVariantType,
      onChangeUser,
      userScore,
      fields,
      totalScore,
      resetFilter,
      totalScoreUser,
      userItem,
      searchFilter,
      showDate,
      fConfig,
      uConfig,
      filters,
      loading,
      selected,
      scorePerItem,
    };
  },
};
</script>
<style></style>
